<template>
  <div>
    <c-search-box @enter="getEquipClassList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :gridHeight="gridClass.height"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getEquipClassList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="itemTable"
          title="설비유형별 설비특성 목록"
          :columns="insItemgrid.columns"
          :data="insItemgrid.data"
          :merge="insItemgrid.merge"
          selection="multiple"
          :usePaging="false"
          :expandAll="true"
          :gridHeight="insItemgrid.height"
          rowKey="equipmentTypeSpecId"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && insItemgrid.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn 
                v-if="editable && updateMode && insItemgrid.data.length > 0" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="insItemgrid.data"
                mappingType="POST"
                label="저장" 
                icon="save" 
                @beforeAction="saveClassSpec"
                @btnCallback="saveClassSpecCallback"/>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemNm'">
              <c-text-column
                :editable="editable"
                :col="col"
                :props="props"
                @datachange="datachange(props, col)"
              >
              </c-text-column>
              <span>
                <div>
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        v-if="editable"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
        </c-table> 
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-class-spec',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            sortable: false,
          },
          {
            name: 'hazardMachineFlagNm',
            field: 'hazardMachineFlagNm',
            label: '유해위험기계기구<br>여부',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
        height: '720px'
      },
      insItemgrid: {
        columns: [
          {
            name: 'item',
            field: 'item',
            label: '항목',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            label: '단위',
            type: 'text',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '720px'
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      isSave: false,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      updateMode: false,
      updateMode2: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      rowTypeName: '',
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.delete.url;
      this.getEquipClassList();
    },
    getEquipClassList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
        this.updateMode = false;
        this.updateMode2 = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeName);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.updateMode = true;
      this.updateMode2 = false;
      // 설비유형별 점검항목 조회
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd
      }
      this.$http.request((_result) => {
        this.insItemgrid.data = this.$_.clone(_result.data);
      },
      () => {
      });
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비유형별 설비특성 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentSpecPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentSpecPopup;
    },
    closeEquipmentSpecPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.insItemgrid.data, {
            item: _item.item,
          });
          if (index === -1) {
            this.insItemgrid.data.splice(0, 0, {
              equipmentTypeCd: this.key.equipmentTypeCd,
              plantCd: this.key.plantCd,
              equipmentTypeSpecId: uid(),
              item: _item.item,
              itemDesc: _item.itemDesc,
              itemValue: _item.itemValue,
              itemUnit: _item.itemUnit,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.insItemgrid.data = this.$_.reject(this.insItemgrid.data, { checkItemNo: item.checkItemNo })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClassSpec() {
      if (this.$comm.validTable(this.insItemgrid.columns, this.insItemgrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.insItemgrid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveClassSpecCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick(null);
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    innerBtnClicked(col, props) {
      this.insItemgrid.data.splice(props.rowIndex+1, 0, {
        equipmentTypeCd: props.row.equipmentTypeCd,
        plantCd: props.row.plantCd,
        equipmentCheckTypeCd: props.row.equipmentCheckTypeCd,
        checkItemNo: props.row.checkItemNo,
        checkItemNm: props.row.checkItemNm,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>
